import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-class-progression"></a><h2>Epic Prestige Class Progressions
    </h2>
    <table border="0" cellPadding="0" cellSpacing="0">
      <tbody>
        <tr>
          <td style={{
            "width": "20%"
          }}>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-arcane-archer">Arcane Archer</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-arcane-trickser">Epic Arcane Trickster</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-assassin">Assassin</a>
          </td>
          <td style={{
            "width": "20%"
          }}>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-blackguard">Blackguard</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-dragon-disciple">Dragon Disciple</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-duelist">Duelist</a>
          </td>
          <td style={{
            "width": "20%"
          }} valign="top">
            <a style={{
              "color": "#579eb6"
            }} href="#epic-dwarven-defender">Dwarven Defender</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-eldritch-knight">Eldritch Knight</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-horizon-walker">Horizon Walker</a>
          </td>
          <td style={{
            "width": "20%"
          }}>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-loremaster">Loremaster</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-mystic-theurge">Mystic Theurge</a>
            <a style={{
              "color": "#579eb6"
            }} href="#epic-shadowdancer">Shadowdancer</a>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-arcane-archer"></a><h3>EPIC ARCANE ARCHER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44208_C1_arcanearcher.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Enchant Arrow:</span> For
every two levels beyond 9th, the potency of the arrows fired by the
arcane archer increases by +1. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hail of Arrows:</span> In
lieu of his or her regular attacks, once per day the arcane archer can
fire an arrow at each and every target within range, with a maximum
number of targets equal to his or her arcane archer class level, as
normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The
arcane archer gains a bonus feat (selected from the list of epic arcane
archer feats) every four levels after 10th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Arcane Archer Bonus
Feat List:</span> Blinding Speed, Combat Archery, Distant Shot, Epic
Prowess, Epic Speed, Epic Toughness, Epic Weapon Focus (any bow other
than a crossbow), Improved Arrow of Death, Improved Combat Casting,
Improved Low-Light Vision, Improved Manyshot, Swarm of Arrows, Uncanny
Accuracy. </p>
    <a id="table-the-epic-arcane-archer"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: The Epic Arcane Archer</span>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Enchant
arrow +6
          </td>
        </tr>
        <tr>
          <td>12th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Enchant
arrow +7 
          </td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Enchant
arrow +8
          </td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Enchant
arrow +9
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Enchant
arrow +10
          </td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-arcane-trickster"></a><h3>EPIC ARCANE TRICKSTER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d4</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The epic
arcane trickster&#8217;s caster level increases by 1 per level gained above
10th. The epic arcane trickster continues to gain new spells per day
(and spells known, if applicable) at each new level, up to the maximum
spells per day and spells known of the arcane spellcasting class to
which the arcane trickster belonged before adding the prestige class.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sneak Attack:</span> The epic
arcane trickster&#8217;s sneak attack damage increases by +1d6 every 2 levels
above 10th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Impromptu Sneak Attack:</span>
The arcane trickster may use this ability one additional time per day
for every 4 levels above 7th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Ranged Legerdemain:</span>
The arcane trickster may use this ability one additional time per day
for every 4 levels above 9th.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
arcane trickster gains a bonus feat selected from the list of epic
arcane trickster feats) every 4 levels after 10th.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Arcane Trickster Bonus
Feat List:</span> Automatic Silent Spell Automatic Still Spell,
Blinding Speed, Epic Skill Focus, Improved Combat Casting, Improved
Sneak Attack, Improved Spell Capacity, Lingering Damage,
Self-Concealment, Sneak Attack of Opportunity, Spell Knowledge, Spell
Opportunity, Spell Stowaway.</p>
    <a id="table-the-epic-arcane-trickster"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: The Epic Arcane Trickster</span>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Imprompty
sneak attack 3/day
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +6d6 
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Ranged
legerdemain 4/day</td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +7d6, bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Impromptu
sneak attack 4/day</td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +8d6</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Ranged
legerdemain 5/day 
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +9d6, bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Impromptu
sneak attack 5/day</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak attack +10d6</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-assassin"></a><h3>EPIC ASSASSIN</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44200_C1_assassin.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d6. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sneak Attack:</span> The epic
assassin&#8217;s sneak attack damage increases by +1d6 every two levels after
9th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Death Attack:</span> The
assassin counts only half his or her class levels beyond 10th when
determining the DC to resist this attack. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The assassin&#8217;s
caster level is equal to his or her class level. The assassin&#8217;s number
of spells per day does not increase after 10th level. The assassin gains no new spells known after 10th level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Uncanny Dodge:</span> The
assassin&#8217;s uncanny dodge bonus on saves against traps doesn&#8217;t increase
after 10th level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Saving Throw Bonus against
Poison:</span> The assassin&#8217;s bonus on saves against poison increases
by +1 every two levels after 10th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
assassin gains a bonus feat (selected from the list of epic assassin
feats) every four levels after 10th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Assassin Bonus Feat
List:</span> Dexterous Fortitude, Dexterous Will, Improved Combat
Casting, Improved Death Attack, Improved Sneak Attack, Improved Spell
Capacity, Legendary Tracker, Lingering Damage, Sneak Attack of
Opportunity, Spell Knowledge, Spontaneous Spell, Superior Initiative,
Tenacious Magic, Uncanny Accuracy. </p>
    <a id="table-the-epic-assassin"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: The Epic Assassin</span>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +6d6
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>+6 save
against poison</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +7d6</td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>+7 save
against poison, bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +8d6 </td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>+8 save
against poison</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +9d6</td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>+9 save
against poison, bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +10d6</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>+10 save against poison</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-blackguard"></a><h3>EPIC BLACKGUARD</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44274_C1_blackguard.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d10. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The
blackguard&#8217;s caster level is equal to his or her class level. The
blackguard&#8217;s number of spells per day does not increase after 10th
level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Smite Good:</span> The epic
blackguard gains one additional daily use of smite good every 5 levels
after 10th.&nbsp; The epic blackguard adds his or her class level to
damage with any smite good attack, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Command Undead:</span> The
blackguard commands undead as a cleric of two levels lower, as normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sneak Attack:</span> The epic
blackguard&#8217;s sneak attack damage increases by +1d6 every three levels
after 10th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Fiendish Servant:</span> Up
to 20th character level, a fiendish servant&#8217;s powers depend on its
master&#8217;s character level, not his or her blackguard class level. After
that, they depend on his or her blackguard level. For every five
blackguard levels above 10th the fiendish servant gains +2 bonus Hit
Dice, its natural armor in-creases by +2, and its Strength and
Intelligence each increase by +1. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Fallen Paladins:</span> A
blackguard who trades in more than ten levels of paladin can gain more
than ten levels of blackguard, but only if his character level is 21st
or higher. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
blackguard gains a bonus feat (selected from the list of epic
blackguard feats) every three levels after 10th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Blackguard Bonus Feat
List:</span> Armor Skin, Devastating Critical, Epic Leadership, Epic
Prowess, Epic Reputation, Epic Toughness, Epic Weapon Focus, Great
Smiting, Improved Aura of Despair, Improved Combat Casting, Improved
Sneak Attack, Improved Spell Capacity, Legendary Commander, Legendary
Rider, Lingering Damage, Negative Energy Burst, Overwhelming Critical,
Perfect Health, Permanent Emanation, Planar Turning, Spontaneous Spell,
Undead Mastery, Unholy Strike, Widen Aura of Despair, Zone of
Animation. </p>
    <a id="table-the-epic-blackguard"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: The Epic Blackguard</span>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +4d6, bonus feat </td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Smite
good 4/day</td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +5d6, bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Sneak
attack +6d6, bonus feat</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Smite good 5/day</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-dragon-disciple"></a><h3>EPIC DRAGON DISCIPLE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d12.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Natural Armor:</span> At 13th
level, and every 3 levels thereafter, the dragon disciple's natural
armor bonus increases by +1 (to +5 at 13th, +6 at 16th, and so on).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Breath Weapon:</span> At 13th
level, and every 4 levels thereafter, the damage dealt by the epic
dragon disciple's breath weapon increases by 2d8.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
dragon disciple gains a bonus feat (selected from the list of epic
dragon disciple feats) every 4 levels after 10th.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Dragon Disciple Bonus
Feat List:</span> Armor Skin, Damage Reduction, Epic Prowess, Epic
Skill Focus (Knowledge [arcana]), Epic Toughness, Extended Life Span,
Flyby Attack&#8224;, Great Charisma, Great Constitution, Great Strength,
Hover&#8224;, Improved Combat Casting, Improved Darkvision, Improved Spell
Capacity, Improved Spell Resistance, Snatch&#8224;, Spell Knowledge,
Wingover&#8224;.</p>
    <p>&#8224;Non-epic feat. Treat the dragon disciple's age category as
one-half his class level for the purpose of the Hover feat.</p>
    <a id="table-the-epic-dragon-disciple"></a><p style={{
      "fontWeight": "bold"
    }}>Table: The Epic Dragon Disciple</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Breath
weapon (8d8), natural armor +5 
          </td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Natural
armor +6</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Breath
weapon (10d8)</td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Natural
armor +7</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-duelist"></a><h3>EPIC DUELIST</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d10</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Precise Strike:</span> Every
five levels above 10th, the extra damage inflicted by the epic
duelist&#8217;s precise strike increases by +1d6.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
duelist gains a bonus feat (selected from the list of epic duelist
feats) every 3 levels after 10th.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Duelist Bonus Feat List:</span>
Blinding Speed, Epic Prowess, Epic Reputation, Epic Skill Focus, Epic
Speed, Epic Weapon Focus (rapier), Exceptional Deflection*, Improved
Combat Reflexes, Improved Whirlwind Attack, Infinite Deflection*,
Perfect Two-Weapon Fighting, Superior Initiative, Two-Weapon Rend.</p>
    <p>*The epic duelist need not have Improved Unarmed Strike to
qualify for these feats, but in that case must be using a light or
one-handed piercing weapon.</p>
    <a id="table-the-epic-duelist"></a><p style={{
      "fontWeight": "bold"
    }}>Table: The Epic Duelist</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}> Precise
strike +3d6</td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Precise strike +4d6</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-dwarven-defender"></a><h3>EPIC DWARVEN DEFENDER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44209_C1_dwarvendefender.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d12. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Defensive Stance:</span> The
epic dwarven defender gains one additional defensive stance per day
every two levels above 9th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Defensive Awareness:</span>
The dwarven defender&#8217;s defensive awareness bonus on saves against traps
doesn&#8217;t increase after 10th level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Damage Reduction:</span> The
epic dwarven defender&#8217;s damage reduction increases by 3 points every
four levels above 10th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Trap Sense (Ex):</span> The
epic dwarven defender&#8217;s bonus increases by +1 every four levels higher
than 8th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
dwarven defender gains a bonus feat (selected from the list of epic
dwarven defender feats) every five levels after 10th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Dwarven Defender Bonus
Feat List:</span> Armor Skin, Bulwark of Defense, Damage Reduction,
Devastating Critical, Energy Resistance, Epic Endurance, Epic Prowess,
Epic Toughness, Epic Weapon Focus, Fast Healing, Improved Combat
Reflexes, Improved Dark-vision, Instant Reload, Mobile Defense,
Overwhelming Critical, Perfect Health, Spellcasting Harrier. </p>
    <a id="table-the-epic-dwarven-defender"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: The Epic Dwarven
Defender</span> </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "100px"
          }}>AC Bonus</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+4</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Defensive
stance 6/day
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+4</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Trap sense +3</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+5</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Defensive
stance 7/day</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+5</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Damage
reduction 9/-</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+5</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat, Defensive stance 8/day</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+6</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Trap sense +4</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+6</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Defensive
stance 9/day</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+6</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Damage
reduction 12/-</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+7</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Defensive
stance 10/day</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+7</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat, trap sense +5</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-eldritch-knight"></a><h3>EPIC ELDRITCH KNIGHT</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d6</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The epic
eldritch knight&#8217;s caster level increases by 1 per level gained above
10th. The epic eldritch knight continues to gain new spells per day
(and spells known, if applicable) at each new level, up to the maximum
spells per day and spells known of the arcane spellcasting class to
which the eldritch knight belonged before adding the prestige class.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
eldritch knight gains a bonus feat (selected from the list of epic
eldritch knight feats) every 4 levels after 10th.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Eldritch Knight Bonus
Feat List:</span> Armor Skin, Automatic Still Spell, Combat Archery,
Damage Reduction, Devastating Critical, Dire Charge, Distant Shot,
Enhance Spell, Epic Prowess, Epic Spell Focus, Epic Spell Penetration,
Epic Spellcasting, Epic Toughness, Epic Weapon Focus, Ignore Material
Components, Improved Combat Casting, Improved Combat Reflexes, Improved
Manyshot, Improved Spell Capacity, Improved Whirlwind Attack, Instant
Reload, Legendary Rider, Overwhelming Critical, Penetrate Damage
Reduction, Perfect Two-Weapon Fighting, Permanent Emanation, Spell
Knowledge, Spell Opportunity, Spell Stowaway, Spellcasting Harrier,
Spontaneous Spell, Storm of Throws, Superior Initiative, Swarm of
Arrows, Tenacious Magic, Two-Weapon Rend, Uncanny Accuracy.</p>
    <a id="table-the-epic-eldritch-knight"></a><p style={{
      "fontWeight": "bold"
    }}>Table: The Epic Eldritch Knight</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
      </tbody>
    </table>
    <a id="epic-horizon-walker"></a><h3>EPIC HORIZON WALKER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Improved Terrain Mastery:</span>
The epic horizon walker may select one of the terrains already in her
terrain mastery repertoire. The insight bonus on attack and damage
rolls against creatures of that terrain increases by +1. The maximum
insight bonus for any one terrain is equal to the epic horizon walker&#8217;s
class level divided by 10, rounded up.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
horizon walker gains a bonus feat (selected from the list of epic
horizon walker feats) every 4 levels after 10th.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Horizon Walker Bonus
Feat List:</span> Armor Skin, Blinding Speed, Energy Resistance, Epic
Endurance, Epic Prowess, Epic Reputation, Epic Skill Focus, Epic Speed,
Epic Toughness, Extended Life Span, Legendary Climber, Perfect Health,
Polyglot.</p>
    <a id="table-the-epic-horizon-walker"></a><p style={{
      "fontWeight": "bold"
    }}>Table: The Epic Horizon Walker</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
Terrain Mastery
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
Terrain Mastery</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
Terrain Mastery</td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus
feat, improved Terrain Mastery</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
Terrain Mastery</td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
Terrain Mastery</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
Terrain Mastery</td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus
feat, improved Terrain Mastery</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved
Terrain Mastery</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Improved Terrain Mastery</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-loremaster"></a><h3>EPIC LOREMASTER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44210_C1_loremaster.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d4. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 4 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> The epic loremaster�s
caster level increases by 1 for each new loremaster level
she attains. If she has more than one spellcasting class,
she must decide which class receives the increase in
caster level. The epic loremaster�s continues to gain new spells per day
(and spells known, if applicable) at each new level, up to the maximum spells per day
and spells known of the spellcasting class to which the loremaster belonged
before adding the prestige class.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Secret:</span> The loremaster
doesn&#8217;t gain additional secrets after 10th level, because there is a
limit to the number of secrets that can be gained, but the character
can choose a lore-master secret instead of a bonus feat. Remember that
a character can&#8217;t select the same secret twice. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Lore:</span> Add the
loremaster&#8217;s class level + Intelligence modifier to all lore checks, as
normal. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
loremaster gains a bonus feat (selected from the list of epic
loremaster feats) every three levels after 10th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Loremaster Bonus Feat
List:</span> Augmented Alchemy, Automatic Quicken Spell, Automatic
Silent Spell, Automatic Still Spell, Craft Epic Magic Arms and Armor,
Craft Epic Rod, Craft Epic Staff, Craft Epic Wondrous Item, Efficient
Item Creation, Enhance Spell, Epic Spell Focus, Epic Spell Penetration,
Epic Spellcasting, Forge Epic Ring, Ignore Material Components,
Improved Combat Casting, Improved Heighten Spell, Improved Metamagic,
Improved Spell Capacity, Intensify Spell, Master Staff, Master Wand,
Multispell, Permanent Emanation, Polyglot, Scribe Epic Scroll, Spell
Knowledge, Spell Stowaway, Spell Opportunity, Spontaneous Spell,
Tenacious Magic. In addition to the feats on this list, the loremaster
can select a loremaster secret instead of a bonus feat. </p>
    <a id="table-the-epic-loremaster"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The Epic Loremaster </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-</td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-mystic-theurge"></a><h3>EPIC MYSTIC THEURGE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d4</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 2 + Int modifier</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> When an
odd-numbered mystic theurge level above 10th is gained she gains new
spells per day (and spells known, if applicable), as if she had gained
a level in an arcane spellcasting class she belonged to previously, up
to the maximum spells per day and spells known by that class. Her
caster level in that class also increases by +1. When an even-numbered
mystic theurge level above 10th is gained she gains new spells per day
(and spells known, if applicable), as if she had gained a level in a
divine spellcasting class she belonged to previously, up to the maximum
spells per day and spells known by that class. Her caster level in that
class also increases by +1.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
mystic theurge gains a bonus feat (selected from the list of epic
mystic theurge feats) every 6 levels after 10th.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Mystic Theurge Bonus
Feat List:</span> Automatic Quicken Spell, Automatic Silent Spell,
Automatic Still Spell, Bonus Domain, Enhance Spell, Epic Spell Focus,
Epic Spell Penetration, Epic Spellcasting, Ignore Material Components,
Improved Alignment-Based Casting, Improved Combat Casting, Improved
Heighten Spell, Improved Metamagic, Improved Spell Capacity, Intensify
Spell, Multispell, Permanent Emanation, Spell Knowledge, Spell
Opportunity, Spell Stowaway, Spontaneous Domain Access, Spontaneous
Spell, Tenacious Magic.</p>
    <a id="table-the-epic-mystic-theurge"></a><p style={{
      "fontWeight": "bold"
    }}>Table: The Epic Mystic Theurge</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-</td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-</td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-</td>
        </tr>
      </tbody>
    </table>
    <a id="epic-shadowdancer"></a><h3>EPIC SHADOWDANCER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/EPIC_Gallery/Gallery1/44161_C1_shadowdancer.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><span style={{
        "fontWeight": "bold"
      }}>Hit Die:</span> d8. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each
Additional Level:</span> 6 + Int modifier. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Summon Shadow:</span> Every
third level gained after 9th adds +2 HD (and the requisite base attack
and base save bonus increases) to the shadowdancer&#8217;s shadow companion. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Shadow Jump:</span> The total
distance of a shadowdancer&#8217;s shadow jump doubles every two levels after
10th. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The epic
shadowdancer gains a bonus feat (selected from the list of epic
shadowdancer feats) every three levels after 20th. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Epic Shadowdancer Bonus Feat
List:</span> Blinding Speed, Dexterous Fortitude, Dexterous Will, Epic
Dodge, Epic Skill Focus, Epic Speed, Exceptional Deflection, Improved
Combat Reflexes, Improved Darkvision, Improved Whirlwind Attack,
Infinite Deflection, Legendary Leaper, Reflect Arrows,
Self-Concealment, Spellcasting Harrier, Superior Initiative. </p>
    <a id="table-the-epic-shadowdancer"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: The Epic Shadowdancer</span>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th colSpan="1" style={{
            "width": "350px"
          }}>Special
          </th>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>12th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Summon
shadow (four of 9 HD each), shadow jump (320 ft.) &nbsp;&nbsp;&nbsp; </td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td>14th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Shadow
jump (640 ft.)</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Summon
shadow (five of 11 HD each)</td>
        </tr>
        <tr>
          <td>16th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Shadow
jump (1,280 ft.), bonus feat</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>-
          </td>
        </tr>
        <tr>
          <td>18th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Summon
shadow (six of 13 HD each), shadow jump (2,560 ft.) &nbsp;&nbsp;&nbsp; </td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Shadow jump (5,120 ft.)</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      